import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { Box } from '@mui/material';
import { COLORS } from '../../constant';
import { Navigate, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { api } from '../../endpoint/endpoint'
import useSignIn from 'react-auth-kit/hooks/useSignIn';
import useIsAuthenticated from 'react-auth-kit/hooks/useIsAuthenticated'

const LoginScreen = () => {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const navigate = useNavigate();
    const isAuthenticated = useIsAuthenticated()
    // console.log('isAuthenticated', isAuthenticated)
    const signIn = useSignIn();
    const handleLogin = async () => {
        const values = {
            email: email,
            password: password,
        }
        try {
            const headers = {
                'Content-Type': 'application/json'
            }
            const response = await axios.post(
                `${api}user/token`,
                JSON.stringify(values),
                { headers: headers }
            )
            console.log(response.data)
            if (response.status===200){
                if(
                signIn({
                     auth: {
                         token: response.data.access,
                         type: 'Bearer'
                     },
                     userState: { name: 'React User', uid: email }
                 })) {
                     // If Login Successfull, then Redirect the user to secure route
                     console.log('logined')
                     navigate('/dashboard');
                     console.log('navigate')
                 }
            }
        } catch (error) {
            console.error('Login error:', error);
        }
    };

    if (isAuthenticated) {
        // If authenticated user, then redirect to secure dashboard

        return (
            <Navigate to={'/dashboard'} replace />
        )
    } else {
        return (
            <Box sx={{ backgroundColor: COLORS.white, display: 'flex', flexDirection: { xs: 'column-reverse', md: 'column-reverse', lg: 'row' }, alignItems: 'center', padding: '32px', justifyContent: 'center', height: '100%' }}>

                <Box sx={{ borderRadius: 10, display: 'flex', flexDirection: 'column', padding: '32px', width: { xs: '200px', md: '500px', lg: '40%' } }}>
                    <Typography variant="h2" component="h1" fontWeight='600' gutterBottom>
                        Đăng Nhập
                    </Typography>
                    <TextField
                        label="Email"
                        margin="normal"
                        fullWidth
                        required
                        autoComplete="username"
                        variant="outlined"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <TextField
                        label="Mật Khẩu"
                        margin="normal"
                        fullWidth
                        required
                        type="password"
                        autoComplete="current-password"
                        variant="outlined"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Checkbox />
                        <Typography variant="body2" component="p">
                            Remember me
                        </Typography>
                    </Box>
                    <Button onClick={handleLogin} variant="contained" sx={{ backgroundColor: COLORS.main, borderRadius: '10px' }} size="large" fullWidth>
                        Đăng Nhập
                    </Button>
                    <Typography align="center" marginBottom={2} marginTop={2}>--hoặc--</Typography>
                    <Button variant="contained" sx={{ backgroundColor: COLORS.main, borderRadius: '10px' }} size="large" fullWidth>
                        Tiếp tục truy cập Google
                    </Button>
                    <Typography variant="body2" component="p" align="center" marginTop={2}>
                        Don't have an account?{' '}
                        <Link href="/signup" component="a" underline="none">
                            Sign Up
                        </Link>
                    </Typography>
                    <Typography variant="body2" component="p" align="center" marginTop={4}>
                        Copyright Your Website 2024
                    </Typography>
                </Box>
                <Box display={{xs:'none', md:'none', lg:'flex'}}>
                    <img src={require('../../assets/img/loginImg.png')} alt='' height="595px" />
                </Box>
            </Box>
        );
    }
};

export default LoginScreen;
