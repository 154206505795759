import { Box, Button, Typography } from '@mui/material'
import React from 'react'
import { COLORS } from '../../constant'

const BotItem = () => {
    return (
        <Box sx={{
            marginBottom: '20px',
            border: '1px solid black',
            borderRadius: '10px',
            padding: '10px',
        }}>
            <Typography sx={{fontWeight:'600'}}>Bot Name</Typography>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                rowGap: '10px',
            }}>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems:'center',
                    }}
                >
                    <Typography>Open Trade</Typography>
                    <Button sx={{ backgroundColor: 'red', width: '100px' }} variant='contained'>Remove</Button>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                >
                    <Typography>Profit</Typography>
                    <Button sx={{ backgroundColor: COLORS.main, width: '100px' }} variant='contained'>Detail</Button>
                </Box>
            </Box>
        </Box>
    )
}

export default BotItem