import { Box, TextField, Typography } from '@mui/material'
import React from 'react'

const StrategyOptionItem = ({title, lable1, lable2, lable3}) => {
  return (
    <Box
        sx={{
            display: 'flex',
            flexDirection:'column',
            marginBottom:4,
        }}
    >
        <Typography fontWeight='600'>{title}</Typography>
        <Box
            sx={{
                marginLeft:5,
                display: 'flex',
                flexDirection:{xs:'column',md:'column', lg:'row'},
                rowGap:2,
                columnGap:2
            }}
        >
        <TextField id={lable1}  label={lable1} variant="standard" />
        {
            lable2 && <TextField id={lable2}  label={lable2} variant="standard" />
        }
                {
            lable3 && <TextField id={lable3}  label={lable3} variant="standard" />
        }
        </Box>
    </Box>
  )
}

export default StrategyOptionItem