import React from 'react'
import { Box, Button, Grid, Typography } from '@mui/material';
import { COLORS } from '../../constant/index'
import TestimonialItem from '../../components/TestimonialItem/TestimonialItem';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Link } from 'react-router-dom';

const Home = () => {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    swipe: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1,
          infinite: true,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  return (
    <Box
      sx={{
        backgroundColor: COLORS.grayBackground,
      }}
    >
      {/* header */}
      <Box sx={{
        padding: { xs: '20px 50px 0px 50px', md: '20px 50px 0px 50px', lg: '20px 130px 0px 150px' },
        backgroundColor: '#13181F',
        display: 'flex',
        flexDirection: { xs: 'column', md: 'column', lg: 'row' },
        justifyContent: 'space-between',
      }}>
        <Box
          sx={{
            width: { xs: '90%', md: '90%', lg: '50%' },
            display: 'flex',
            flexDirection: 'column',
            rowGap: '20px',
            justifyContent: 'flex-start',
            paddingTop: '50px'
          }}>
          <Typography color={COLORS.white} variant="h3" fontWeight='600' textAlign='left' maxWidth='523px'>Thiết kế Bot giao dịch tự động sử dụng AI đơn giản thông minh mà không cần biết code</Typography>
          <Typography color={COLORS.white} variant="h6" >Lorem2 ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt</Typography>
          <Box display='flex' flexDirection='row' gap={2}>
            <Link to='/login'>
              <Button sx={{ backgroundColor: COLORS.main, height: '52px', borderRadius: '15px' }} variant='contained'><Typography>Mở Tài Khoản</Typography></Button>
            </Link>
            <Link to='/login'>
              <Button sx={{ backgroundColor: COLORS.main, height: '52px', borderRadius: '15px' }} variant='contained'>Dashboard</Button>
            </Link>
          </Box>
        </Box>
        <Box
          sx={{
            width: { xs: '90%', md: '90%', lg: '50%' },
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Box sx={{ marginLeft: { xs: '0px', md: '0px', lg: '150px' } }}>
            <img src={require('../../assets/img/PhoneMockup.png')} alt='' width="100%" style={{minWidth:'400px'}} height="100%" />
          </Box>
          <Box display={{ xs: 'none', md: 'none', lg: 'flex' }}>
            <img src={require('../../assets/img/Chart.png')} alt='' width="250px" height="154px" style={{ position: 'absolute', bottom: '25px', right: '350px' }} />
          </Box>
          <Box display={{ xs: 'none', md: 'none', lg: 'flex' }}>
            <img src={require('../../assets/img/Rectangle1.png')} alt='' width="228px" height="157px" style={{ position: 'absolute', top: '150px', right: '50px' }} />
          </Box>
          <Box display={{ xs: 'none', md: 'none', lg: 'flex' }}>
            <img src={require('../../assets/img/Frame10.png')} alt='' width="261px" height="52px" style={{ position: 'absolute', top: '350px', right: '350px' }} />
          </Box>
        </Box>
        <Box display={{ xs: 'none', md: 'none', lg: 'flex', position: 'absolute', top: '800px' }}>
          <img src={require('../../assets/img/Frame11.png')} alt='' width="1140px" height="130px" />
        </Box>
      </Box>
      {/* header */}

      {/* content */}
      <Box
        sx={{
          marginTop: '150px',
          padding: { xs: '20px 50px 0px 50px', md: '20px 50px 0px 50px', lg: '20px 130px 0px 150px' },
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
        }}
      >
        <Typography variant="h3" fontWeight='500' alignItems='center'>Nơi Cả Thế Giới<Typography variant="h3" color={COLORS.main} fontWeight='500'>Tạo Lập Thị Trường</Typography></Typography>
        <Typography variant="h6" marginTop='20px' width={{ lg: '500px' }} height='68px' fontWeight='500'>Hãy cùng 60 triệu nhà giao dịch và nhà đầu tư nắm lấy tương lai trong tay của chính mình.</Typography>
        <Grid container spacing={2} columns={{ xs: 1, md: 2, lg: 2 }} width='100%' sx={{ borderRight: '10px', marginTop: '50px' }}>
          <Grid width={{ xs: '100%', md: '100%', lg: '48%' }}>
            <Box height={300}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                gap: '20px',
                borderRight: { lg: '1px solid #C9C9C9' },
                borderBottom: { lg: '1px solid #C9C9C9' },
              }}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                  paddingTop: '60px'
                }}
              >
                <img src={require('../../assets/img/Frame.png')} alt='' width="90px" height="90px" />
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  rowGap: '10px',
                  justifyContent: 'center'
                }}
              >
                <Typography variant='h5' fontWeight='500' textAlign='left'>Kết nối trực tiếp</Typography>
                <Typography width={{ lg: 348 }} textAlign='left'>R kết hợp với Công ty Cổ Phần Chứng khoán Hồ Chí Minh để có kết nối trực tiếp gửi các lệnh giao dịch lên sở giao dịch chứng khoán.</Typography>
                <Typography color={COLORS.main} fontWeight='600' sx={{ cursor: 'pointer' }} textAlign='left'>Xem thêm</Typography>
              </Box>
            </Box>
          </Grid>
          <Grid width={{ xs: '100%', md: '100%', lg: '48%' }}>
            <Box height={300}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                gap: '20px',
                borderBottom: { lg: '1px solid #C9C9C9' },
              }}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                  paddingTop: '60px'
                }}
              >
                <img src={require('../../assets/img/Frame.png')} alt='' width="90px" height="90px" />
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  rowGap: '10px',
                  justifyContent: 'center'
                }}
              >
                <Typography variant='h5' fontWeight='500' textAlign='left'>Kết nối trực tiếp</Typography>
                <Typography width={{ lg: 348 }} textAlign='left'>R kết hợp với Công ty Cổ Phần Chứng khoán Hồ Chí Minh để có kết nối trực tiếp gửi các lệnh giao dịch lên sở giao dịch chứng khoán.</Typography>
                <Typography color={COLORS.main} fontWeight='600' sx={{ cursor: 'pointer' }} textAlign='left'>Xem thêm</Typography>
              </Box>
            </Box>
          </Grid>
          <Grid width={{ xs: '100%', md: '100%', lg: '48%' }}>
            <Box height={300}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                gap: '20px',
                borderRight: { lg: '1px solid #C9C9C9' }
              }}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                  paddingTop: '60px'
                }}
              >
                <img src={require('../../assets/img/Frame.png')} alt='' width="90px" height="90px" />
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  rowGap: '10px',
                  justifyContent: 'center'
                }}
              >
                <Typography variant='h5' fontWeight='500' textAlign='left'>Kết nối trực tiếp</Typography>
                <Typography width={{ lg: 348 }} textAlign='left'>R kết hợp với Công ty Cổ Phần Chứng khoán Hồ Chí Minh để có kết nối trực tiếp gửi các lệnh giao dịch lên sở giao dịch chứng khoán.</Typography>
                <Typography color={COLORS.main} fontWeight='600' sx={{ cursor: 'pointer' }} textAlign='left'>Xem thêm</Typography>
              </Box>
            </Box>
          </Grid>
          <Grid width={{ xs: '100%', md: '100%', lg: '48%' }}>
            <Box height={300}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                gap: '20px',
              }}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                  paddingTop: '60px'
                }}
              >
                <img src={require('../../assets/img/Frame.png')} alt='' width="90px" height="90px" />
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  rowGap: '10px',
                  justifyContent: 'center'
                }}
              >
                <Typography variant='h5' fontWeight='500' textAlign='left'>Kết nối trực tiếp</Typography>
                <Typography width={{ lg: 348 }} textAlign='left'>R kết hợp với Công ty Cổ Phần Chứng khoán Hồ Chí Minh để có kết nối trực tiếp gửi các lệnh giao dịch lên sở giao dịch chứng khoán.</Typography>
                <Typography color={COLORS.main} fontWeight='600' sx={{ cursor: 'pointer' }} textAlign='left'>Xem thêm</Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
      {/* content */}

      {/*features*/}
      <Box
      >
        {/*features  1*/}
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'column', lg: 'row' },
            alignItems: 'center',
            justifyContent: 'center',
            padding: { xs: '20px 50px 0px 50px', md: '20px 50px 0px 50px', lg: '50px 50px 0px 180px' },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'flex-start',
              width: '50%',
              position: 'relative'
            }}
          >
            <img src={require('../../assets/img/PhoneMockup.png')} alt='' width="100%" height="550px" style={{maxWidth:'330px', minWidth:'300px'}} />
            <Box
              sx={{
                position: 'absolute', bottom: '30px', right: { xs: '0px', md: '150px', lg: '350px' },
              }}
            >
              <img src={require('../../assets/img/Card.png')} alt='' width="270px" height="185px" style={{}} />
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'flex-start',
              rowGap: '20px',
              width: { xs: '100%', md: '100%', lg: '50%' },
            }}
          >
            <Typography color={COLORS.main} variant="h4" fontWeight='600'>GIAO DỊCH THÔNG MINH</Typography>
            <Typography variant="h4" width={{ lg: 388 }} fontWeight='600'>Màn hình giao dịch thân thiện</Typography>
            <Typography width={{ lg: 444 }}>Hệ thống giao dịch của ENTRADE được thiết kế tối ưu trải nghiệm của khách hàng, giúp nhà đầu tư dễ dàng theo dõi thị trường, đặt lệnh nhanh chóng.</Typography>
            <ul>
              <li>Thiết kế thân thiện</li>
              <li>Dễ dàng sử dụng</li>
              <li>Đặt lệnh nhanh chóng</li>
              <li>Đồ thị trực quan</li>
              <li>Liên tục cải tiến</li>
            </ul>
          </Box>
        </Box>
        {/*features  2*/}
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column-reverse', md: 'column-reverse', lg: 'row' },
            alignItems: 'center',
            justifyContent: 'center',
            padding: { xs: '20px 50px 0px 50px', md: '20px 50px 0px 50px', lg: '50px 50px 0px 180px' },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'flex-start',
              rowGap: '20px',
              width: { xs: '100%', md: '100%', lg: '50%' },
            }}
          >
            <Typography color={COLORS.main} variant="h4" fontWeight='600'>GIAO DỊCH THÔNG MINH</Typography>
            <Typography variant="h4" width={{lg:388}} fontWeight='600'>Màn hình giao dịch thân thiện</Typography>
            <Typography width={{ lg: 444 }}>Hệ thống giao dịch của ENTRADE được thiết kế tối ưu trải nghiệm của khách hàng, giúp nhà đầu tư dễ dàng theo dõi thị trường, đặt lệnh nhanh chóng.</Typography>
            <ul>
              <li>Thiết kế thân thiện</li>
              <li>Dễ dàng sử dụng</li>
              <li>Đặt lệnh nhanh chóng</li>
              <li>Đồ thị trực quan</li>
              <li>Liên tục cải tiến</li>
            </ul>
          </Box>
          <Box
            sx={{
              justifyContent: 'center',
              alignItems: 'center',
              width: { xs: '100%', md: '100%', lg: '50%' },
              position: 'relative'
            }}
          >
            <Box >
              <img src={require('../../assets/img/image9.png')} alt='' width="100%" height="auto" />
            </Box>
          </Box>
        </Box>
        {/*features  3*/}
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'column', lg: 'row' },
            alignItems: 'center',
            justifyContent: 'center',
            padding: { xs: '20px 50px 0px 50px', md: '20px 50px 0px 50px', lg: '50px 50px 0px 180px' },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'flex-start',
              width: '50%',
              position: 'relative'
            }}
          >
            <img src={require('../../assets/img/PhoneMockup.png')} alt='' width="100%" height="550px" style={{maxWidth:'330px', minWidth:'300px'}} />
            <Box
              sx={{
                display:{xs:'none', md:'flex', lg:'flex'},
                position: 'absolute', bottom: '30px', left: { xs: '0px', md: '50px', lg: '280px' },
              }}
            >
              <img src={require('../../assets/img/Card.png')} alt='' width="270px" height="185px" style={{}} />
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'flex-start',
              rowGap: '20px',
              width: { xs: '100%', md: '100%', lg: '50%' },
            }}
          >
            <Typography color={COLORS.main} variant="h4" fontWeight='600'>GIAO DỊCH THÔNG MINH</Typography>
            <Typography variant="h4" width={{ lg: 388 }} fontWeight='600'>Màn hình giao dịch thân thiện</Typography>
            <Typography width={{ lg: 444 }}>Hệ thống giao dịch của ENTRADE được thiết kế tối ưu trải nghiệm của khách hàng, giúp nhà đầu tư dễ dàng theo dõi thị trường, đặt lệnh nhanh chóng.</Typography>
            <ul>
              <li>Thiết kế thân thiện</li>
              <li>Dễ dàng sử dụng</li>
              <li>Đặt lệnh nhanh chóng</li>
              <li>Đồ thị trực quan</li>
              <li>Liên tục cải tiến</li>
            </ul>
          </Box>
        </Box>
      </Box>
      {/*features*/}

      {/* download */}
      <Box
        sx={{
          marginTop: '50px',
          padding: { xs: '20px 50px 0px 50px', md: '20px 50px 0px 50px', lg: '20px 130px 0px 150px' },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            rowGap: '20px',
          }}
        >
          <Typography variant='h3' fontWeight='500'>Ứng Dụng Trên Điện Thoại</Typography>
          <Typography width={{ lg: 528 }}>R kết hợp với Công ty Cổ Phần Chứng khoán Hồ Chí Minh để có kết nối trực tiếp gửi các lệnh giao dịch lên sở giao dịch chứng khoán.</Typography>
          <Box
            sx={{
              display: 'flex',
              columnGap: '20px'
            }}
          >
            <Button sx={{ backgroundColor: COLORS.main, width: { lg: '170px' }, height: '50px', borderRadius: '15px' }} variant='contained'>App Store</Button>
            <Button sx={{ backgroundColor: COLORS.main, width: { lg: '170px' }, height: '50px', borderRadius: '15px' }} variant='contained'>Google Play</Button>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '20px'
          }}
        >
          <Box sx={{ display: { xs: 'flex', md: 'flex', lg: 'flex' } }}>
            <img src={require('../../assets/img/PhoneMockup.png')} alt='' width="231px" height="349px" />
          </Box>
          <Box sx={{ display: { xs: 'none', md: 'flex', lg: 'flex' } }}>
            <img src={require('../../assets/img/PhoneMockup.png')} alt='' width="231px" height="349px" />
          </Box>
          <Box sx={{ display: { xs: 'none', md: 'flex', lg: 'flex' } }}>
            <img src={require('../../assets/img/PhoneMockup.png')} alt='' width="231px" height="349px" />
          </Box>
          <Box sx={{ display: { xs: 'none', md: 'none', lg: 'flex' } }}>
            <img src={require('../../assets/img/PhoneMockup.png')} alt='' width="231px" height="349px" />
          </Box>
        </Box>
      </Box>
      {/* download */}
      {/* CTA */}
      <Box
        sx={{
          display: 'flex',
          marginTop: '50px',
          padding: { xs: '20px 50px 0px 50px', md: '20px 50px 0px 50px', lg: '20px 130px 0px 150px' },
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          rowGap: '20px',
        }}
      >
        <Typography variant='h3' fontWeight='500'>Mở Tài Khoản</Typography>
        <Typography width={{ lg: 528 }}>R kết hợp với Công ty Cổ Phần Chứng khoán Hồ Chí Minh để có kết nối trực tiếp gửi các lệnh giao dịch lên sở giao dịch chứng khoán.</Typography>
        <Box
          sx={{
            display: 'flex',
            columnGap: '20px'
          }}
        >
          <Button sx={{ backgroundColor: COLORS.main, width: '170px', height: '50px', borderRadius: '15px' }} variant='contained'>Mở Tài Khoản</Button>
        </Box>
      </Box>
      {/* CTA */}

      {/*testimonial*/}
      <Box
        sx={{
          width: '100%',
          height: '300px',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: '50px',
          backgroundColor: COLORS.main,
          position: 'relative',
          marginBottom:'250px'
        }}
      >
        <Typography paddingTop={5} paddingBottom={5} width='100%' textAlign='center' color='white' variant='h3' fontWeight='500'>Khách Hàng Của Chúng Tôi Nói Gì</Typography>
        <Box
          sx={{
            display: 'block',
            backgroundColor: 'white',
            padding: '30px',
            paddingLeft: { lg: '100px', xs: '10px' },
            boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px'
          }}
        >
          <Slider {...settings}>
            <div>
              <TestimonialItem />
            </div>
            <div>
              <TestimonialItem />
            </div>
            <div>
              <TestimonialItem />
            </div>
          </Slider>
        </Box>
      </Box>
      {/*testimonial*/}

      {/*Footer*/}
      {/* <Footer home={true}/> */}
      {/*Footer*/}

    </Box>
  )
}

export default Home
