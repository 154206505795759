import { Box, Button, Step, StepButton, Stepper, TextField, Typography } from '@mui/material';
import React from 'react'
import CustomizedMenus from '../../components/MenuDrop/MenuDrop'
import { Link } from 'react-router-dom';
import { COLORS } from '../../constant';
import steps from '../../mockData/steps';

const ConfigScreen = () => {
  return (
    <Box sx={{ backgroundColor: COLORS.gray, paddingTop: '20px', display: "flex", flexDirection: { xs: 'column', md: 'column', lg: 'column' }, alignItems: 'center' }}>
      <Stepper sx={{ backgroundColor: COLORS.white, padding: '20px', borderRadius: '10px', width: '60%', display:{xs:'none', md:'none',lg:'flex'} }} nonLinear activeStep={1}>
        {steps.map((label, index) => (
          <Step key={label}>
            <Link to={index === 1 || index ===2 ? '/config' : '/create-strategy'}>
              <StepButton color="inherit">
                {label}
              </StepButton>
            </Link>
          </Step>
        ))}
      </Stepper>
      <Box sx={{marginBottom:'50px', backgroundColor: COLORS.gray, padding: "20px 40px 0 40px", height: "100%", display: "flex", flexDirection: { xs: 'column', md: 'column', lg: 'row' }, columnGap: '20px', rowGap: '50px', alignItems: 'center' }}>
        <Box
          sx={{
            width: { xs: '90%', md: '100%', lg: '50%' },
            height: '500px',
            display: 'flex',
            flexDirection: 'column',
            rowGap: '50px',
            backgroundColor: COLORS.white,
            padding: 5,
            borderRadius: '10px',
          }}
        >
          <Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                rowGap: '15px'
              }}
            >
              <Typography>Exchange Config</Typography>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: { xs: 'column', md: 'column', lg: 'row' },
                  columnGap: "20px",
                  rowGap: "20px",
                  // justifyContent: 'flex-start',
                  alignItems: 'flex-start',
                }}
              >
                <CustomizedMenus />
                <CustomizedMenus />
                <TextField id="standard-basic" label="Token" variant="standard" />
                <Button sx={{ backgroundColor: COLORS.main }} variant='contained'>Add</Button>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            width: { xs: '90%', md: '100%', lg: '50%' },
            height: '500px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            rowGap: '50px',
            backgroundColor: COLORS.white,
            padding: 5,
            borderRadius: '10px',
          }}
        >
          <Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                rowGap: '15px'
              }}
            >
              <Typography>Notify Config</Typography>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: { xs: 'column', md: 'column', lg: 'row' },
                  columnGap: "20px",
                  rowGap: "20px",
                  // justifyContent: 'flex-start',
                  alignItems: 'flex-start',
                }}
              >
                <CustomizedMenus />
                <CustomizedMenus />
                <TextField id="standard-basic" label="name" variant="standard" />
                <Button sx={{ backgroundColor: COLORS.main }} variant='contained'>Add</Button>
              </Box>
            </Box>
          </Box>
          <Link to="/bots" >
            <Button sx={{ width: '100%', backgroundColor: COLORS.main }} variant='contained'>Next</Button>
          </Link>
        </Box>
      </Box>
    </Box>
  )
}

export default ConfigScreen;
