import { Box, Button, Typography } from '@mui/material'
import React from 'react'
import { COLORS } from '../../constant'
import MenuDrop from '../../components/MenuDrop/MenuDrop'
import SyncAltOutlinedIcon from '@mui/icons-material/SyncAltOutlined';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import LineChart from '../../components/LineChart/LineChart'
import BarChart from '../../components/BarChart/BarChart'
import { Link } from 'react-router-dom';

const DashBoard = () => {
    return (
        <Box
            sx={{
                backgroundColor: COLORS.grayBackground,
                padding: { xs: '20px 50px 20px 50px', md: '20px 50px 20px 50px', lg: '50px 70px 50px 70px' },
            }}
        >
            {/*header */}
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexDirection: { xs: 'column', md: 'row', lg: 'row' },
                    alignItems: 'center',
                    columnGap: 2,
                    rowGap: 2,
                }}>
                <Box sx={{ display: 'flex', columnGap: 5, alignItems: 'center', flexDirection: { xs: 'column', md: 'row', lg: 'row' } }}>
                    <Typography variant='h4' fontWeight='600'>Dashboard</Typography>
                    <Box
                        sx={{ display: 'flex', columnGap: 2, alignItems: 'center' }}
                    >
                        <Typography>Show</Typography>
                        <MenuDrop />
                    </Box>
                </Box>
                <Box sx={{ display: 'flex', columnGap: 2, rowGap: 2, flexDirection: { xs: 'column', md: 'row', lg: 'row' } }}>
                    <Button sx={{ width: 241, height: 52, boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px', backgroundColor: COLORS.white, color: 'black', borderRadius: '10px' }} variant='contained'>
                        <SyncAltOutlinedIcon sx={{ marginRight: 2 }} />
                        Quản lý chiến thuật
                    </Button>
                    <Link to='/select-market'>
                        <Button sx={{ width: 241, height: 52, boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px', backgroundColor: COLORS.main, color: 'white', borderRadius: '10px' }} variant='contained'>
                            <AddCircleOutlineOutlinedIcon sx={{ marginRight: 2 }} />
                            Tạo chiến thuật
                        </Button>
                    </Link>
                </Box>
            </Box>
            {/*header */}

            {/*indicators */}
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginTop: 5,
                    borderRadius: '10px',
                    flexDirection: { xs: 'column', md: 'row', lg: 'row' },
                    alignItems: 'center',
                    boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
                    backgroundColor: COLORS.white,
                    padding: 3,

                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        borderRight: { lg: '1px solid gray' },
                        borderBottom: { xs: '1px solid gray', md: 'none', lg: 'none' },
                        alignItems: { xs: 'center' },
                        padding: 1,
                        width: { xs: '100%', md: '20%', lg: '20%' }
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            columnGap: 1,
                        }}
                    >
                        <Typography>Total</Typography>
                        <Typography sx={{ backgroundColor: COLORS.main, borderRadius: 10, display: 'flex', justifyContent: 'center' }} color='white' fontSize={14} width={60}>+2.5%</Typography>
                    </Box>
                    <Typography variant='h6' fontWeight='600'>1,545,778</Typography>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        borderRight: { lg: '1px solid gray' },
                        borderBottom: { xs: '1px solid gray', md: 'none', lg: 'none' },
                        alignItems: { xs: 'center' },
                        padding: 1,
                        width: { xs: '100%', md: '20%', lg: '20%' }
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            columnGap: 1,
                        }}
                    >
                        <Typography>Total</Typography>
                        <Typography sx={{ backgroundColor: COLORS.main, borderRadius: 10, display: 'flex', justifyContent: 'center' }} color='white' fontSize={14} width={60}>+2.5%</Typography>
                    </Box>
                    <Typography variant='h6' fontWeight='600'>1,545,778</Typography>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        borderRight: { lg: '1px solid gray' },
                        borderBottom: { xs: '1px solid gray', md: 'none', lg: 'none' },
                        alignItems: { xs: 'center' },
                        padding: 1,
                        width: { xs: '100%', md: '20%', lg: '20%' }
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            columnGap: 1,
                        }}
                    >
                        <Typography>Total</Typography>
                        <Typography sx={{ backgroundColor: COLORS.main, borderRadius: 10, display: 'flex', justifyContent: 'center' }} color='white' fontSize={14} width={60}>+2.5%</Typography>
                    </Box>
                    <Typography variant='h6' fontWeight='600'>1,545,778</Typography>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        borderRight: { lg: '1px solid gray' },
                        borderBottom: { xs: '1px solid gray', md: 'none', lg: 'none' },
                        alignItems: { xs: 'center' },
                        padding: 1,
                        width: { xs: '100%', md: '20%', lg: '20%' }
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            columnGap: 1,
                        }}
                    >
                        <Typography>Total</Typography>
                        <Typography sx={{ backgroundColor: COLORS.main, borderRadius: 10, display: 'flex', justifyContent: 'center' }} color='white' fontSize={14} width={60}>+2.5%</Typography>
                    </Box>
                    <Typography variant='h6' fontWeight='600'>1,545,778</Typography>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        borderRight: { lg: '1px solid gray' },
                        borderBottom: { xs: '1px solid gray', md: 'none', lg: 'none' },
                        alignItems: { xs: 'center' },
                        padding: 1,
                        width: { xs: '100%', md: '20%', lg: '20%' }
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            columnGap: 1,
                        }}
                    >
                        <Typography>Total</Typography>
                        <Typography sx={{ backgroundColor: COLORS.main, borderRadius: 10, display: 'flex', justifyContent: 'center' }} color='white' fontSize={14} width={60}>+2.5%</Typography>
                    </Box>
                    <Typography variant='h6' fontWeight='600'>1,545,778</Typography>
                </Box>


            </Box>
            {/*indicators */}

            {/*analysis */}
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginTop: 5,
                    borderRadius: '10px',
                    flexDirection: { xs: 'column', md: 'row', lg: 'row' },
                    columnGap:2,
                    rowGap:2,

                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        rowGap: 2,
                        justifyContent: 'space-between',
                        borderRadius: '10px',
                        backgroundColor: COLORS.white,
                        alignItems: 'center',
                        boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
                        padding: 2,
                        width: { xs: '100%', md: '100%', lg: '25%' }

                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            width: '100%',
                            borderBottom: '1px solid gray',
                            paddingBottom: 1,
                        }}
                    >
                        <Typography color='#6C6C6C'>Tên</Typography>
                        <Typography>Shark123</Typography>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            width: '100%',
                            borderBottom: '1px solid gray',
                            paddingBottom: 1,
                        }}
                    >
                        <Typography color='#6C6C6C'>Trạng thái</Typography>
                        <Typography color='green'>Running</Typography>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            width: '100%',
                            borderBottom: '1px solid gray',
                            paddingBottom: 1,
                        }}
                    >
                        <Typography color='#6C6C6C'>Thời gian tạo</Typography>
                        <Typography>15/05/2023</Typography>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            width: '100%',
                            borderBottom: '1px solid gray',
                            paddingBottom: 1,
                        }}
                    >
                        <Typography color='#6C6C6C'>Thời gian thực hiện</Typography>
                        <Typography>24 ngày</Typography>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            width: '100%',
                            borderBottom: '1px solid gray',
                            paddingBottom: 1,
                        }}
                    >
                        <Typography color='#6C6C6C'>Thời gian tạo</Typography>
                        <Typography>Tamtam1213</Typography>
                    </Box>
                    <Box
                        sx={{ width: '100%' }}
                    >
                        <LineChart />
                    </Box>
                </Box>
                <Box
                    sx={{
                        width: {xs:'100%', md:'100%',lg:'75%'},
                        display: 'flex',
                        flexDirection: 'column',
                        backgroundColor:COLORS.white,
                        borderRadius:'10px',
                        boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
                        padding:2,
                    }}
                >
                    <Box
                        sx={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: {xs:'column', md:'column', lg:'row'},
                            justifyContent:'space-between',
                            alignItems:'center',
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                padding: 1,
                                width: { xs: '100%', md: '20%', lg: '20%' }
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    columnGap: 1,
                                }}
                            >
                                <Typography>Total</Typography>
                                <Typography sx={{ backgroundColor: COLORS.main, borderRadius: 10, display: 'flex', justifyContent: 'center' }} color='white' fontSize={14} width={60}>+2.5%</Typography>
                            </Box>
                            <Typography variant='h6' fontWeight='600'>1,545,778</Typography>
                        </Box>
                        <MenuDrop />
                    </Box>
                    <Box
                        sx={{
                            width: '100%',
                            height: 500,
                            marginTop: 2,
                        }}
                    >
                        <BarChart />
                    </Box>
                </Box>
            </Box>
            {/*analysis */}



        </Box>
    )
}

export default DashBoard