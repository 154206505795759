import { Box, Button, Modal, TextField, Typography } from '@mui/material'
import React from 'react'
import { COLORS } from '../../constant'
import CustomizedMenus from '../MenuDrop/MenuDrop'
import AddIcon from '@mui/icons-material/Add';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

const IndicatorItem = ({ number, onAdd, onRemove, lengthOfArray, add }) => {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const mockData = [0]
    return (
        <Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    rowGap: '15px'
                }}
            >
                <Typography fontWeight='600'>Bộ điều kiện {number + 1}</Typography>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: { xs: 'column', md: 'column', lg: 'row' },
                        columnGap: "20px",
                        rowGap: "20px",
                        // justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                    }}
                >
                    <CustomizedMenus />
                    {/* <CustomizedMenus /> */}
                    <TextField id="standard-basic" label="name" variant="standard" />
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: { xs: 'column', md: 'row', lg:'row' },
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            rowGap: 2,
                            columnGap: 2,
                        }}
                    >
                        <Button onClick={onAdd} sx={{ backgroundColor: COLORS.main }} variant='contained'><AddIcon fontSize='small' /></Button>
                        <Button onClick={() => onRemove(number)} disabled={lengthOfArray === 1} sx={{ backgroundColor: COLORS.red, width:'50px' }} variant='contained'><DeleteOutlineIcon /></Button>
                    </Box>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        columnGap: "30px",
                        alignItems: 'center',
                        width: "100%",
                    }}
                >
                    {
                        add &&
                        <Box 
                            sx={{
                                display: 'flex',
                                flexDirection: {xs:'column',md:'row',lg:'row'},
                                columnGap: "30px",
                                alignItems:'center',
                                width: "100%",
                            }}
                        >
                        <Button ><Typography fontSize={12}>+ Add more indicator</Typography></Button>
                        <Button onClick={handleOpen}><Typography fontSize={12}>+ AI gen indicator</Typography></Button>
                        </Box>
                    }

                </Box>
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            Type your idea
                        </Typography>
                        <Box
                            sx={{
                                marginBottom: '50px',
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'flex-end',
                            }}
                        >
                            <Button sx={{ backgroundColor: COLORS.main, position: 'fixed' }} variant='contained'>Add All</Button>
                        </Box>
                        <Box
                            sx={{
                                height: '500px',
                                overflow: 'hidden',
                                overflowY: 'scroll',
                                border: '1px solid black',
                                marginBottom: '10px',
                                borderRadius: '10px',
                                padding: '10px',

                            }}
                        >
                            {
                                mockData.map((item, index) => <IndicatorItem key={index} number={index} />)
                            }
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'row', columnGap: '10px' }}>
                            <TextField
                                placeholder='Type something'
                                sx={{
                                    width: '100%',
                                }}
                            />
                            <Button sx={{ backgroundColor: COLORS.main }} variant='contained'>Go</Button>
                        </Box>
                    </Box>
                </Modal>
            </Box>
        </Box>
    )
}

export default IndicatorItem

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};