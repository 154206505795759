import { LoginScreen, Home, SelectMarket, CreateStrategy, ConfigScreen, TradeHistory, Bots, DashBoard, } from "../screens"

const publicRoutes = [
    { path: '/login', component: LoginScreen},
    { path: '/', component: Home},
]

const privatesRoutes = [
    { path: '/select-market', component: SelectMarket},
    { path: '/create-strategy', component: CreateStrategy},
    { path: '/config', component: ConfigScreen},
    { path: '/trade-history', component: TradeHistory},
    { path: '/bots', component: Bots},
    { path: '/dashboard', component: DashBoard},
]

export { publicRoutes, privatesRoutes }