const COLORS = {
    primary: '#FF9800',
    secondary: '#DDF0FF',
    main: '#41B06E',
    gray: '#B4B4B8',
  
    offwhite: '#F3F4F8',
    white: '#FFFFFF',
    black: '#000000',
    red: '#e81e4d',
    green: '#90D26D',
    yellow: '#FFF455',
  
    //background color
    lightWhite: '#FFF',
    grayBackground: '#f5f5f5',
  };

  const SIZES = {
    xSmall: 10,
    small: 12,
    medium: 16,
    large: 20,
    xLarge: 24,
    xxLarge: 44,
    iconSize: 28,
  };

export {COLORS, SIZES}