import { Box, Button, Typography } from '@mui/material'
import Grid2 from '@mui/material/Unstable_Grid2/Grid2'

import React from 'react'
import { Link } from 'react-router-dom'
import { COLORS } from '../../constant'

const SelectMarket = () => {
  return (
    <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: {xs:'250px 40px 0 40px', sm:'0 40px 0 40px',md:'0px 40px 0 40px',lg:'0 40px 30px 40px'} }}>
      <Typography mt={4} variant="h2" fontWeight="500" gutterBottom sx={{ justifyContent: 'center', alignItems: 'center', padding: '0 40px 0 40px' }}>
        Select Market
      </Typography>
      <Grid2 container spacing={{ xs: 2, md: 3 }} columns={8} sx={{ width: '100%' }}>
        <Grid2 xs={8} sm={4} md={4}>
          <Link to="/create-strategy" style={{ textDecoration: 'none' }}>
            <Button
              variant="contained"
              sx={{
                backgroundColor: COLORS.main,
                height: '250px',
                width: '100%',
                borderRadius: 10,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >Crypto</Button>
          </Link>
        </Grid2>
        <Grid2 xs={8} sm={4} md={4}>
          <Link to="/create-strategy" style={{ textDecoration: 'none' }}>
            <Button
              variant="contained"
              sx={{
                backgroundColor: COLORS.main,
                height: '250px',
                width: '100%',
                borderRadius: 10,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >Crypto</Button>
          </Link>
        </Grid2>
        <Grid2 xs={8} sm={4} md={4}>
          <Link to="/create-strategy" style={{ textDecoration: 'none' }}>
            <Button
              variant="contained"
              sx={{
                backgroundColor: COLORS.main,
                height: '250px',
                width: '100%',
                borderRadius: 10,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >Crypto</Button>
          </Link>
        </Grid2>
        <Grid2 xs={8} sm={4} md={4}>
          <Link to="/create-strategy" style={{ textDecoration: 'none' }}>
            <Button
              variant="contained"
              sx={{
                backgroundColor: COLORS.main,
                height: '250px',
                width: '100%',
                borderRadius: 10,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >Crypto</Button>
          </Link>
        </Grid2>
      </Grid2>
    </Box>
  )
}

export default SelectMarket