import {combineReducers, createStore} from '@reduxjs/toolkit';
import messageSlice from './features/MessageSlice';
import authSlice from './features/UserSlice';
// import postSlice from './features/PostSlice';
import {persistReducer, persistStore} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// import agentSlice from './features/AgentSlice';

const persistConfig = {
  key: 'root',
  storage,
};

const rootReducer = combineReducers({
  message: messageSlice,
  auth: persistReducer(persistConfig, authSlice),
  // agents: persistReducer(persistConfig, agentSlice),
  // posts: postSlice,
});

export const store = createStore(rootReducer);
export const persistor = persistStore(store);
