import { Box, Typography } from '@mui/material'
import React from 'react'
import { COLORS } from '../../constant'

const TestimonialItem = () => {
  return (
    <Box width={320} 
    sx={{
        backgroundColor:'white', 
        display:'flex',
        flexDirection:'column',
        rowGap:'20px'
        }}>
        <Typography color={COLORS.main} fontSize={20} fontWeight='600'>“Incredible Experience”</Typography>
        <Typography>Tempor, vivamus blandit quisque nulla at. Duis eu in ut diam aliquet facilisis faucibus imperdiet dictumst. Odio eros sagittis consequat, duis integer. Egestas eu facilisi tincidunt nibh.</Typography>
        <Typography fontWeight='600'>Jerome Bell</Typography>
        <Typography>CTO, Waverio</Typography>
    </Box>
  )
}

export default TestimonialItem