import { Box, Button, Step, StepButton, Stepper, Typography } from '@mui/material';
import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import { COLORS } from '../../constant';
import steps from '../../mockData/steps';
import IndicatorItem from '../../components/IndicatorItem/IndicatorItem';
import StrategyOptionItem from '../../components/StrategyOptionItem/StrategyOptionItem';

const CreateStrategy = () => {
  const [indicators, setIndicators] = useState([0])
  const handleAdd = (index) => {
    setIndicators((prevIndicators) => [...prevIndicators, index + 1]);
  };

  const handleRemove = indexRemove => {
    const updatedIndicators = [...indicators]
    updatedIndicators.splice(indexRemove, 1)
    setIndicators(updatedIndicators);
  };

  return (
    <Box sx={{ backgroundColor: COLORS.gray,  paddingTop: '20px', display: "flex", flexDirection: { xs: 'column', md: 'column', lg: 'column' }, alignItems: 'center' }}>
      <Stepper sx={{ backgroundColor: COLORS.white, padding: '20px', borderRadius: '10px', width: '60%', display: { xs: 'none', md: 'none', lg: 'flex' } }} nonLinear activeStep={0}>
        {steps.map((label, index) => (
          <Step key={label}>
            <Link to={index === 0 || index === 2 ? '/create-strategy' : '/config'}>
              <StepButton color="inherit">
                {label}
              </StepButton>
            </Link>
          </Step>
        ))}
      </Stepper>
      <Box sx={{marginBottom:'50px', padding: { xs: "20px 0px 20px 0px", md: "20px 0px 20px 0px", lg: "20px 40px 0 40px" }, width: '90%', display: "flex", flexDirection: { xs: 'column', md: 'column', lg: 'column' }, rowGap: '30px', columnGap: '20px', alignItems: 'center' }}>
        <Box
          sx={{
            width: { xs: '70%', md: '80%', lg: '90%' },
            // height: '550px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems:'center',
            rowGap: '50px',
            backgroundColor: COLORS.white,
            padding: 5,
            borderRadius: '10px',
          }}
        >
          <Box>
            <StrategyOptionItem title="Mã" lable1={"Mã Giao Dịch"} lable2="Thời Gian Nến" />
            <StrategyOptionItem title="Vị thế giao dịch" lable1={"Vị Thế Mua Hoặc Bán"} lable2="Khối Lượng" />
            <Box>
              <Typography fontWeight='600'>Điều kiện mở vị thế</Typography>
              <Box
                sx={{
                  marginLeft: 5,
                  marginTop: 2,
                }}
              >
                {
                  indicators.map((item, index) => <IndicatorItem key={index} number={index} onAdd={() => handleAdd(index)} onRemove={handleRemove} lengthOfArray={indicators.length} add={true} />)
                }
              </Box>
            </Box>
            <Box marginTop={4}>
              <Typography fontWeight='600'>Điều kiện đóng vị thế</Typography>
              <Box
                sx={{
                  marginLeft: 5,
                  marginTop: 2,
                }}
              >
                {
                  indicators.map((item, index) => <IndicatorItem key={index} number={index} onAdd={() => handleAdd(index)} onRemove={handleRemove} lengthOfArray={indicators.length} add={true} />)
                }
              </Box>
            </Box>
            <StrategyOptionItem title="Tỉ lệ chốt lời/cắt lỗ" lable1={"Chốt lời %"} lable2="Cắt lỗ %" />
            <StrategyOptionItem title="Tên chiến thuật" lable1={"Tên chiến thuật"} />
            <StrategyOptionItem title="Backtest" lable1={"Từ ngày"} lable2="Đến ngày" lable3="Vốn đầu tư" />
          </Box>
          <Button sx={{ backgroundColor: COLORS.main, width: '125px' }} variant='contained'>Backtest</Button>
        </Box>
      </Box>
    </Box>
  )
}

export default CreateStrategy;
