import './App.css';
import { Route, Routes } from 'react-router-dom';
import { Box } from '@mui/material';
import ResponsiveAppBar from './components/AppBar/AppBar';
import RequireAuth from '@auth-kit/react-router/RequireAuth'
import { publicRoutes, privatesRoutes } from './routes/index'
import Footer from './components/Footer/Footer';

function App() {
  return (
    <Box sx={{height: '100vh' }}>
      <ResponsiveAppBar />
        <Routes>
          {publicRoutes.map((route, index) => {
            const Page = route.component
            return <Route key={index}
              path={route.path}
              element={<Page />} />

          })}

          {privatesRoutes.map((route, index) => {
            const Page = route.component
            return (
              <Route
                key={index}
                path={route.path}
                element={<RequireAuth fallbackPath={'/login'}><Page /></RequireAuth>}
              />
            );
          })}
        </Routes>
      <Footer/>
    </Box>
  );
}

export default App;
