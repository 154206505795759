import { Box, Step, StepButton, Stepper, Typography } from '@mui/material';
import React from 'react'
import { COLORS } from '../../constant';
import BotItem from '../../components/BotItem/BotItem';
import { Link } from 'react-router-dom';
import steps from '../../mockData/steps';

const Bots = () => {
  return (
    <Box sx={{ backgroundColor: COLORS.gray, padding: "20px 40px 40px 40px", display: "flex", flexDirection: { xs: 'column', md: 'column', lg: 'column' }, columnGap: '20px', rowGap: '30px', alignItems: 'center' }}>
      <Stepper sx={{ backgroundColor: COLORS.white, padding: '20px', borderRadius: '10px', width: '60%', display:{xs:'none', md:'none',lg:'flex'} }} nonLinear activeStep={2}>
        {steps.map((label, index) => (
          <Step key={label}>
            <Link to={index !== 2 && index === 0 ? '/create-strategy' : '/config'}>
              <StepButton color="inherit">
                {label}
              </StepButton>
            </Link>
          </Step>
        ))}
      </Stepper>
      <Box
        sx={{
          width: { xs: '90%', md: '100%', lg: '90%' },
          // height: '600px',
          display: 'flex',
          flexDirection: 'column',
          rowGap: '50px',
          backgroundColor: COLORS.white,
          padding: 5,
          borderRadius: '10px',
        }}
      >
        <Typography sx={{ borderBottom: ' 2px black solid', paddingBottom: '10px', width: '100%', fontWeight: '600' }}>Bots</Typography>
        <Box
          // sx={{ overflow: 'hidden', overflowY: 'scroll' }}
        >
          <BotItem />
          <BotItem />
          <BotItem />
          <BotItem />
          <BotItem />
          <BotItem />
        </Box>
      </Box>
    </Box>
  )
}

export default Bots;
