import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  Value: 'sonagent',
};

const messageSlice = createSlice({
  name: 'messageSlice,',
  initialState,
  //actions
  reducers: {
    changeId: (state, actions) => {
      state.Value = actions.payload.id;
    },
  },
});

export const {changeId} = messageSlice.actions;

export default messageSlice.reducer;
