import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import './index.css';
import App from './App';
import { store } from './redux/store';
import AuthProvider from 'react-auth-kit';
import createStore from 'react-auth-kit/createStore';

const storeAuth = createStore({
  authName:'_auth',
  authType:'cookie',
  cookieDomain: window.location.hostname,
  cookieSecure: window.location.protocol === 'https:',
})

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <AuthProvider  store={storeAuth}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
        </AuthProvider>
    </Provider>
  </React.StrictMode>
);


