import { Box, Grid, Typography } from '@mui/material'
import React from 'react'
import { COLORS } from '../../constant'
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';

const Footer = ({home}) => {
  return (
    <Box sx={{
        // marginTop: '50px',
        backgroundColor: '#13181F',
        display: 'flex',
        padding: { xs: '20px 50px 20px 50px', md: '20px 50px 0px 50px', lg: '50px 100px 50px 130px' },
      }}>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={12} md={3} lg={3}>
            <Box sx={{
              height: '100%',
              display: 'flex',
              flexDirection: { lg: 'column', md: 'row', xs: 'column' },
              justifyContent: { lg: 'center' },
              // alignItems: 'center',
            }}>
              <img src={require('../../assets/img/logo.png')} alt='' width="100px" height="100px" />
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Typography color={COLORS.white}>Phone: 024 11111 1750</Typography>
                <Typography color={COLORS.white}>Adress: Go Vap, HCM</Typography>
                <Typography color={COLORS.white}>Email: info@R.com</Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={3} lg={3}>
            <Box sx={{
              height: '100%',
              display: 'flex',
              flexDirection: { lg: 'column', md: 'row', xs: 'row' },
              justifyContent: { lg: 'center' },
              rowGap: '5px'
            }}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Typography color={COLORS.white}>For Exporter</Typography>
                <Typography color={COLORS.white}>Develop new sourcing area </Typography>
                <Typography color={COLORS.white}>Certification Consultancy</Typography>
                <Typography color={COLORS.white}>Trade Promotion</Typography>
                <Typography color={COLORS.white}>For Importer</Typography>
                <Typography color={COLORS.white}>Natural Ingredients</Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={3} lg={3}>
            <Box sx={{
              height: '100%',
              display: 'flex',
              flexDirection: { lg: 'column', md: 'row', xs: 'row' },
              justifyContent: { lg: 'center' },
              rowGap: '5px'
            }}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Typography color={COLORS.white}>About Us</Typography>
                <Typography color={COLORS.white}>News </Typography>
                <Typography color={COLORS.white}>For Members</Typography>
                <Typography color={COLORS.white}>Innovative Hub</Typography>
                <Typography color={COLORS.white}>FAQ</Typography>
                <Typography color={COLORS.white}>Contact us</Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={3} lg={3}>
            <Box sx={{
              height: '100%',
              display: 'flex',
              flexDirection: { lg: 'column', md: 'row', xs: 'column' },
              // justifyContent: 'center',
              paddingTop: '20px',
              rowGap: '5px'
            }}>
              <Typography color={COLORS.white}>Liên hệ với chúng tôi</Typography>
              <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: '10px',
              }}>
                <FacebookIcon sx={{ backgroundColor: 'white' }} />
                <InstagramIcon sx={{ backgroundColor: 'white' }} />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
  )
}

export default Footer