import { Box, Typography } from '@mui/material';
import React from 'react'
// import CustomizedMenus from '../../components/MenuDrop/MenuDrop'
// import { Link } from 'react-router-dom';
import { COLORS } from '../../constant';

const TradeHistory = () => {
  return (
    <Box sx={{backgroundColor: COLORS.gray ,padding: "20px 40px 0 40px", height: "100%", display: "flex", flexDirection: {xs:'column', md: 'column', lg:'row'}, columnGap:'20px', rowGap: '50px', alignItems:'center' }}>
      <Box 
        sx={{
          width: {xs:'100%', md: '100%', lg:'50%'},
          height: '500px',
          display: 'flex',
          flexDirection: 'column',
          rowGap: '50px',
          backgroundColor: COLORS.white,
          padding: 5,
          borderRadius: 10,
        }}
      >
        <Typography sx={{borderBottom:' 2px black solid', paddingBottom:'10px' ,width:'100%', fontWeight:'600'}}>Order</Typography>
      </Box>
      <Box 
        sx={{
          width: {xs:'100%', md: '100%', lg:'50%'},
          height: '500px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent:'space-between',
          rowGap: '50px',
          backgroundColor: COLORS.white,
          padding: 5,
          borderRadius: 10,
        }}
      >
      </Box>
    </Box>
  )
}

export default TradeHistory;
